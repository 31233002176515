import Button, { ButtonSize } from 'components/atoms/Tailwind/Button';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useContext } from 'react';
import { OnBoardingContext } from './Onboarding';
import Answer from './Answer';
import { useTranslation } from 'react-i18next';

function Question() {
  const {
    currentQuestion,
    getQuestion,
    nextQuestion,
    previousQuestion,
  } = useContext(OnBoardingContext);
  const { t } = useTranslation();

  return (
    <div className='flex flex-col justify-between w-screen sm:w-[603px] p-4 py-2 sm:pl-[12px]'>
      <div className='w-fit'>
        <span className='font-bold text-2xl sm:text-3xl text-black-midnight-500'>
          {t(getQuestion().title)}
        </span>
      </div>
      <div className='w-fit pb-6'>
        <span className='text-base text-wrap'>{t(getQuestion().question)}</span>
      </div>
      <div className='text-center pb-6'>
        <Answer />
      </div>
      <div className='flex justify-between items-center'>
        <div
          className='flex text-sm xl:text-md text-blue-respect-500 cursor-pointer'
          aria-hidden='true'
          onClick={previousQuestion}
        >
          {currentQuestion > 0 && (
            <>
              <div>
                <ArrowLeftIcon
                  className='text-blue-respect-500 h-5 w-5'
                  aria-hidden='true'
                />
              </div>
              <div>{t('onboarding.button.back')}</div>
            </>
          )}
        </div>
        <div>
          <Button
            size={ButtonSize.MEDIUM}
            text={
              <>
                <span className='text-base leading-5 mr-1'>{t('onboarding.button.next')}</span>
                <ArrowRightIcon className='h-4 w-4' aria-hidden='true' />
              </>
            }
            onClick={nextQuestion}
          />
        </div>
      </div>
    </div>
  );
}

export default Question;

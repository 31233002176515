import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n';
import { useAppContext } from 'context/AppContext';

function Language({ children }: PropsWithChildren) {
  const { search } = useLocation();
  const { setLanguage: setAppLanguage } = useAppContext();

  const setLanguage = useCallback((lng: string) => {
    localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
    setAppLanguage(lng);
  }, [setAppLanguage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const localLanguage = localStorage.getItem('i18nextLng');
    const lng: string | null | undefined = searchParams.get('lang');

    setLanguage(lng ?? localLanguage ??  process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en');
  }, [search, setLanguage]);

  

  return children;
}

export default Language;

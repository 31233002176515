import { HTMLInputTypeAttribute, createContext, useEffect, useState } from 'react';
import Main from './Main';
import { useAnswerCompanyQuestions, useGetCompany } from 'services/companyService';
import chosenCompanyVar from 'utils/graphql/localState/chosenCompany';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';

interface Key {
  [key: string]: string;
}

interface Question {
  question: string;
  title: string;
  inputType: HTMLInputTypeAttribute | 'textarea' | 'multiple-text';
  prefix?: string;
  //seperatable by ','
  options?: string;
  type?: string;
  placeholder?: string;
  translate?: boolean;
}
interface Questions {
  [key: string]: Question;
}

export interface IOnBoardingContext {
  progress: string;
  setProgress: (progress: string) => void;
  currentQuestion: number;
  answers: Key;
  questions: string[];
  nextQuestion: () => void;
  previousQuestion: () => void;
  getQuestion: () => Question;
  setAnswer: (answer: string) => void;
  getAnswer: () => string;
  getTitleForQuestion: () => string;
  submitAnswers(): Promise<void>;
  loading: boolean;
  currentScreen: string;
}
export const OnBoardingContext = createContext<IOnBoardingContext>({
  progress: '0%',
  setProgress: () => {},
  currentQuestion: 0,
  answers: {},
  questions: [],
  nextQuestion: () => {},
  previousQuestion: () => {},
  getQuestion: () => ({} as Question),
  setAnswer: () => {},
  getAnswer: () => '',
  getTitleForQuestion: () => '',
  submitAnswers: async () => {},
  loading: false,
  currentScreen: 'Start',
});

const currentQuestions: Questions = {
  no_of_employee_question: {
    question: 'onboarding.question.no_of_employees.question',
    title: 'onboarding.question.no_of_employees.title',
    inputType: 'input',
    type: 'number',
    placeholder: 'onboarding.question.no_of_employees.placeholder',
    translate: true,
  },
  internal_communication_question: {
    question: 'onboarding.question.internal_communication.question',
    title: 'onboarding.question.internal_communication.title',
    inputType: 'textarea',
  },
  expectations_question: {
    question: 'onboarding.question.expectations_question.question',
    title: 'onboarding.question.expectations_question.title',
    inputType: 'textarea',
  },
  company_website: {
    question: 'onboarding.question.company_website.question',
    title: 'onboarding.question.company_website.title',
    inputType: 'input-prefix',
    prefix: 'https://',
    placeholder: 'www.example.com',
    type: 'url',
  },
  hear_about_us: {
    question: 'onboarding.question.hear_about_us.question',
    title: 'onboarding.question.hear_about_us.title',
    inputType: 'select-boxes',
    options: 'onboarding.question.hear_about_us.options',
  },
};

const onboardingEventsAnsweredNext: ButtonLayerEvent = {
  item: 'OnBoarding Question',
  details: '',
  origin: 'OnBoarding',
};


function OnBoarding() {
  const [progress, setProgress] = useState<string>('0%');
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<Key>({});
  const [questions, setQuestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const { answerCompanyQuestions, loading: answerLoading } = useAnswerCompanyQuestions();
  const [currentScreen, setCurrentScreen] = useState<string>('Start');
  const { data, getCompany } = useGetCompany();
  const chosenCompany = chosenCompanyVar.init() ?? chosenCompanyVar.restore();

  
  useEffect(() => {
    const setCurrentQuestions: string[] = Object.keys(currentQuestions);
    setQuestions(setCurrentQuestions);
    
    chosenCompany && getCompany(chosenCompany.uuid);
  }, [chosenCompany]);

  useEffect(() => {
    setAnswers(data?.company.onboardingAnswers || {});
    setLoading(false);
  }, [data]);

  useEffect(() => {
    setLoading(answerLoading);
  }, [answerLoading]);

  function nextQuestion() {
    if (currentScreen === 'Start') {
      setCurrentScreen('Question');
    } else if (currentQuestion === questions.length - 1) {
      pushEventToDataLayer(currentQuestion);
      setCurrentScreen('End');
    } else {
      pushEventToDataLayer(currentQuestion);
      setCurrentQuestion(currentQuestion + 1);
    }
  }

  function pushEventToDataLayer(question: number, next = true) {
    if (next) {
      const event:ButtonLayerEvent  = { ...onboardingEventsAnsweredNext }; 
      event.details += questions[question];
      pushToButtonDataLayer(event);
    }
  }

  function previousQuestion() {
    setCurrentQuestion(currentQuestion - 1);
  }

  function getQuestion() {
    return currentQuestions[questions[currentQuestion]];
  }

  function setAnswer(answer: string) {
    answers[questions[currentQuestion]] = answer;
    setAnswers(answers);
  }

  function getAnswer() {
    return answers[questions[currentQuestion]];
  }

  function getTitleForQuestion() {
    return currentQuestions[questions[currentQuestion]].title;
  }

  async function submitAnswers() {
    setLoading(true);
    await answerCompanyQuestions(
      chosenCompany ? chosenCompany.id : '',
      { ...answers },
    );
  }

  return (
    <OnBoardingContext.Provider
      value={{
        progress,
        setProgress,
        currentQuestion,
        nextQuestion,
        previousQuestion,
        answers,
        setAnswer,
        getAnswer,
        questions,
        getQuestion,
        getTitleForQuestion,
        submitAnswers,
        loading,
        currentScreen,
      }}
    >
      <Main />
    </OnBoardingContext.Provider>
  );
}

export default OnBoarding;

import { InputHTMLAttributes, useContext, useEffect, useState } from 'react';
import { OnBoardingContext } from './Onboarding';
import TextArea from 'components/atoms/Tailwind/TextArea';
import Input from 'components/atoms/Tailwind/Input';
import MultiInput from 'components/atoms/Tailwind/MultiInputs';
import { SelectBoxes } from 'components/atoms/Tailwind/SelectBoxes/SelectBoxes';
import { useTranslation } from 'react-i18next';

interface IInputPrefixProps extends InputHTMLAttributes<HTMLInputElement> {
  prefix: string;
}

function InputPrefix({ prefix, placeholder, onChange, value, type } : IInputPrefixProps) {
  return (
    <div>
      <div className="flex py-[9px] px-4 bg-white rounded-md shadow-sm ring-1 ring-inset ring-grey-dark-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-respect-600">
        <span className="flex select-none items-center text-gray-500 sm:text-sm">
          {prefix}
        </span>
        <input
          type={type}
          placeholder={placeholder}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
}

function Answer() {
  const { setAnswer, getAnswer, getQuestion } = useContext(OnBoardingContext);
  const [currentValue, setCurrentValue] = useState<string>(getAnswer() ?? '');
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentValue(getAnswer() ?? '');
  }, [setCurrentValue, getAnswer]);

  function onChange({ target: { value } }: { target: { value: string } }) {
    setAnswer(value);
    setCurrentValue(value);
  }

  function onSelect(value: string) {
    onChange({ target: { value } });
  }

  function getInput() {
    if (getQuestion().inputType === 'multiple-inputs') {
      return <MultiInput mainValue={getAnswer()} setMainValue={setAnswer} />;
    } else if (getQuestion().inputType === 'input') {
      const placeholder = getQuestion().translate ? t(getQuestion().placeholder || '')   : getQuestion().placeholder;
      return  <Input value={currentValue} onChange={onChange} type={getQuestion().type ?? 'text'} placeholder={placeholder}/>;
    } else if (getQuestion().inputType === 'input-prefix') {
      return <InputPrefix value={currentValue} onChange={onChange} prefix={getQuestion().prefix || ''} placeholder={getQuestion().placeholder} type={getQuestion().type}/>;
    } else if (getQuestion().inputType === 'select-boxes') {
      return <SelectBoxes options={ t(getQuestion().options || '').split(';') || []} onSelect={onSelect} selected={getAnswer()} />;
    }
    return <TextArea value={currentValue} onChange={onChange} rows={3} />;
  }

  return getInput();
}

export default Answer;

import { tv } from 'tailwind-variants';


export const styleBox = tv({
  base: 'flex py-3 px-4 min-w-[283px] bg-white rounded-md shadow-sm ring-1 ring-inset ring-grey-dark-300 cursor-pointer text-black-midnight-light-300 text-base hover:text-blue-respect-500 hover:ring-2 hover:ring-inset hover:ring-blue-respect-600 hover:bg-blue-respect-50',
  variants: {
    selected: {
      true: 'ring-blue-respect-600 text-blue-respect-500',
      false: '',
    },
  },
});

export const styleBoxText = tv({
  base: 'flex select-none items-center',
});
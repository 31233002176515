import { ADMIN_ROLES, UserContext, UserRole } from 'contexts/UserContext';
import { useCallback, useContext } from 'react';

interface IAuthorizeService {
  canRemoveUser: () => boolean;
  canAddUser: () => boolean;
  canChangeUserRole: () => boolean;
  canCreateHRIntegration: () => boolean;
  canViewHome: () => boolean;
}

export const useAuthorize: () => IAuthorizeService = () => {
  const { user } = useContext(UserContext);

  const canRemoveUser = useCallback(() => {
    // Need to remove this piece of code after roles are assigned
    if (user?.role === UserRole.UNDEFINED) return true;

    return [...ADMIN_ROLES].includes(user?.role || '');
  }, [user]);

  const canCreateHRIntegration = useCallback(() => {
    // Need to remove this piece of code after roles are assigned
    if (user?.role === UserRole.UNDEFINED) return true;

    return [...ADMIN_ROLES, UserRole.HR].includes(user?.role || '');
  }, [user]);

  const canAddUser = useCallback(() => {
    // Need to remove this piece of code after roles are assigned
    if (user?.role === UserRole.UNDEFINED) return true;

    return [...ADMIN_ROLES, UserRole.HR].includes(user?.role || '');
  }, [user]);

  const canViewHome = useCallback(() => {
    if (user?.role === UserRole.UNDEFINED) return true;

    return [...ADMIN_ROLES, UserRole.HR].includes(user?.role || '');
  }, [user]);

  return {
    canRemoveUser,
    canCreateHRIntegration,
    canAddUser,
    canChangeUserRole: canRemoveUser,
    canViewHome,
  };
};
import { Card } from '@urbansportsclub/components/Card';
import { IRoleCard } from './RoleCard.types';
import * as styles from './RoleCard.variants';
import { Radio } from '@urbansportsclub/components/Radio';
import { Text } from '@urbansportsclub/components/Text';
import { useAppContext } from 'context/AppContext';

type Language = 'en' | 'de' | 'nl' | 'pt' | 'es' | 'fr';
export default function RoleCard({
  title,
  iconBg,
  description,
  Icon,
  onClick,
  isSelected = false,
  isFullWidth = false,
} : IRoleCard) {
  const { language } = useAppContext();
  return (
      <Card onClick={onClick} className={styles.containerDiv({ isSelected, isFullWidth, lang: language as Language })} variant='option'>
          <div className='flex flex-row space-between'>
            <div className={styles.cardsContainerDiv({ isFullWidth })}>
              <div className={styles.iconSVG({ isFullWidth, className: iconBg })}>{Icon}</div>
              <div className='flex flex-col'>
                <Text intent="md" weight='regular' className='text-base'>{title}</Text>
                <Text intent="md" weight='regular' className={styles.descriptionText({ isFullWidth })}>{description}</Text>
              </div>
            </div>
            <div>
              <Radio checked={isSelected} className={styles.radio()}/>
            </div>
          </div>
      </Card>
  );
}

'use client';

import { useContext, useEffect, useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  ChevronRightIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import { Link, NavLink } from 'react-router-dom';
import * as styles from './SideBarMenu.variants';
import routes, { RouterProps } from 'routes';
import { Badge } from '@urbansportsclub/components/Badge';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import { ReactComponent as Logo } from 'assets/images/logo-white-landscape-2.svg';
import { ReactComponent as LogoTop } from 'assets/images/logo-white.svg';
import LanguageSelectorNew from 'components/molecules/LanguageSelectorNew/LanguageSelector';
import { logout } from 'services/authService';
import HRWizardCard from '../HRWizardCard/HRWizardCard';
import { UserContext, UserRole } from 'contexts/UserContext';
import { useAuthorize } from 'services/authorizeService';
import { fetchCompanies } from 'services/companyService';
import { AxiosResponse } from 'axios';
import { Company } from 'models/company';
import { CustomError } from 'utils/api/error';

function Syncing() {
  const { t } = useTranslation();
  return <div className='flex items-center gap-x-2 border-[0.094rem] border-grey-dark-700 rounded-md min-w-full p-3 mb-2 text-sm font-normal text-[#FFFFFFF2]'>
        <LoadingIcon className="fixed-sidebar-layout__loading-icon rotating text-blue-respect-500" role="img" />
        {t('hr_integration.card.syncing')}
    </div>;
}

export default function SideBarMenu() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [numberOfCompanies, setNumberOfCompanies] = useState<number>(0);

  const { t } = useTranslation();
  const { showSyncing, company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { canCreateHRIntegration } = useAuthorize();

  useEffect(() => { }, [showSyncing, company]);

  useEffect(() => {
    fetchCompanies().then(
      (response: AxiosResponse<Company[]> | CustomError) => {
        if ('data' in response) {
          setNumberOfCompanies(response.data.length);
        }
      },
    );
  }, []);

  const filterRoute = (route: RouterProps) => {
    const integrationName = company?.integrationName?.split('-')[0] ?? '';
    
    const isReportPath: boolean =
      (route.path === '/report' && company?.featureFlags.isReportsEnabled) ||
      false;

    const isAllowedByUserRole =
      (user?.role !== UserRole.UNDEFINED &&
        route.allowedRoles &&
        route.allowedRoles?.includes(user?.role || '')) ||
      user?.role === UserRole.UNDEFINED;

    const isAllowedByIntegration: boolean =
      (route.allowedIntegration &&
        route.allowedIntegration?.includes(integrationName)) ||
      false;

    return isAllowedByUserRole && (isReportPath || isAllowedByIntegration);
  };

  function bottomMenu() {
    return ( 
      <>
        <ul className="-mx-2 space-y-1">
            <li>
              {canCreateHRIntegration() && company?.featureFlags.isHrIntegrationEnabled && !company?.isHrIntegrationConnected && !showSyncing && <HRWizardCard />}
            </li>
            <li>
              {showSyncing && (<Syncing />)}
            </li>
            <li>
              {
                numberOfCompanies <= 1 ? (
                  <div className={styles.menuItem()}>
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-white capitalize bg-gray-800 text-[0.625rem] font-medium pt-[0.6px] text-gray-400 group-hover:text-white">
                            {company?.name[0]}
                    </span>
                    <div className='line-clamp-2 leading-4'>{company?.name}</div>
                  </div>
                ) : (
                  <Link to="/choose-company" className={styles.menuItem()}>
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-white capitalize bg-gray-800 text-[0.625rem] font-medium pt-[0.6px] text-gray-400 group-hover:text-white">
                            {company?.name[0]}
                    </span>
                    <div className='line-clamp-2 leading-4'>{company?.name}</div>
                    <ChevronRightIcon className="h-6 w-6 shrink-0 ml-auto" />
                  </Link>
                )
              }                
            </li>
            <li>
                <LanguageSelectorNew />
            </li>
            <li onClick={logout}>
                <div className='group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 cursor-pointer hover:bg-gray-800 hover:text-white'>
                    <ArrowLeftStartOnRectangleIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    {t('menu.logout')}
                </div>
            </li>
        </ul>
      </>
    );
  }


  function toggleMenu() {
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={toggleMenu}
          className="relative z-50 lg:hidden"
        >
          {/* <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          /> */}

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <button onClick={toggleMenu} className='absolute right-0 top-4 text-transparent'>Close</button>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black-midnight-light-800 px-6 pb-2 ring-1 ring-white/10">
                <div className="flex items-center shrink-0 max-w-screen">
                  <Logo className="h-10 w-48 mt-[0.688rem]" />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {routes.filter(filterRoute).map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item?.path || ''}
                              className={({ isActive }) => {
                                return styles.menuItem({ active: isActive });
                              }}
                            >
                              {item.icon ? (
                                <item.icon
                                  aria-hidden="true"
                                  className="h-6 w-6 shrink-0"
                                />
                              ) : (
                                <></>
                              )}
                              {t(item.name || '')}
                              {item.isNew && <Badge mode="dark">{t('badge.new_label')}</Badge>}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto mb-8">{bottomMenu()}</li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black-midnight-light-800 px-6">
            <div className="flex shrink-0 items-center max-w-screen">
              <Logo className="h-12 mt-5" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {routes.filter(filterRoute).map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item?.path || ''}
                          className={({ isActive }) => {
                            return styles.menuItem({ active: isActive });
                          }}
                        >
                          {item.icon ? (
                            <item.icon
                              aria-hidden="true"
                              className="h-6 w-6 shrink-0"
                            />
                          ) : (
                            <></>
                          )}
                          {t(item.name || '')}
                          {item.isNew && <Badge mode="dark">{t('badge.new_label')}</Badge>}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto mb-8">{bottomMenu()}</li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-black-midnight-light-800 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <LogoTop className="h-[1.875rem]" />
        </div>
      </div>

      {!sidebarOpen ?
          <button
            type="button"
            onClick={toggleMenu}
            className="absolute top-2.5 right-3.5 z-[55] p-2.5 text-gray-400 ml-auto lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        : 
          <button
            type="button"
            onClick={toggleMenu}
            className="absolute top-2.5 right-3.5 z-[55] p-2.5 ml-auto -z-10 lg:hidden"
          >
            <span className="sr-only">Close sidebar</span>
            <XMarkIcon
              aria-hidden="true"
              className="h-6 w-6 text-white"
            />
          </button>
      }
    </>
  );
}

import { CheckIcon } from '@heroicons/react/16/solid';
import { Text } from '@urbansportsclub/components/Text';
import { useTranslation } from 'react-i18next';

interface IDataManagementSection {
  title: string;
  description: string;
  features: { name: string; description: string }[];
  background: string;
}
const DataManagementSections: IDataManagementSection[] = [
  {
    title: 'hrwizard.data_management.section.required_data.title',
    background: 'text-blue-respect-500',
    description:
      'hrwizard.data_management.section.required_data.description',
    features: [
      {
        name: 'hrwizard.data_management.section.required_data.work_email.title',
        description:
          'hrwizard.data_management.section.required_data.work_email.description',
      },
      {
        name: 'hrwizard.data_management.section.required_data.start_date.title',
        description:
          'hrwizard.data_management.section.required_data.start_date.description',
      },
      {
        name: 'hrwizard.data_management.section.required_data.employee_status.title',
        description:
          'hrwizard.data_management.section.required_data.employee_status.description',
      },
      {
        name: 'hrwizard.data_management.section.required_data.personal_email.title',
        description:
          'hrwizard.data_management.section.required_data.personal_email.description',
      },
      {
        name: 'hrwizard.data_management.section.required_data.termination_date.title',
        description:
          'hrwizard.data_management.section.required_data.termination_date.description',
      },
    ],
  },
  {
    title: 'hrwizard.data_management.section.additional_data.title',
    background: 'text-blue-respect-200',
    description:
      'hrwizard.data_management.section.additional_data.description',
    features: [
      {
        name: 'hrwizard.data_management.section.additional_data.employee_number.title',
        description:
          'hrwizard.data_management.section.additional_data.employee_number.description',
      },
      {
        name: 'hrwizard.data_management.section.additional_data.employee_type.title',
        description:
          'hrwizard.data_management.section.additional_data.employee_type.description',
      },
      {
        name: 'hrwizard.data_management.section.additional_data.work_location.title',
        description:
          'hrwizard.data_management.section.additional_data.work_location.description',
      },
    ],
  },
];

export default function DataManagement() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:gap-x-12 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div className="lg:sticky lg:col-start-1 lg:row-span-1 lg:row-start-1 lg:top-[164px]">
        <Text as="h5" intent="xl" weight="bold">
          {t('hrwizard.data_management.title')}
        </Text>
        <Text as="span" intent="md" className="text-base">
          {t('hrwizard.data_management.subtitle1')}
        </Text>
        <div className="mt-6">
          <Text as="span" intent="md" className="text-base">
            {t('hrwizard.data_management.subtitle2')}
          </Text>
        </div>
      </div>
      <div className="col-span-3 space-y-10 lg:mx-8 lg:col-start-2 lg:row-start-2 lg:mx-auto lg:-mt-[338px]">
        {DataManagementSections.map((section) => (
          <div key={section.title}>
            <div>
              <Text as="span" intent="lg" weight="bold">
                {t(section.title)}
              </Text>
              <Text as="p" intent="md" className="text-base">
                {t(section.description)}
              </Text>
            </div>
            <dl className="grid grid-cols-1 gap-x-16 gap-y-8 text-gray-600 mt-8 sm:grid-cols-2">
              {section.features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline-flex items-baseline font-semibold text-black-midnight-500 text-base">
                    <CheckIcon
                      aria-hidden="true"
                      className={`absolute left-0 top-1 h-6 w-6 ${section.background}`}
                    />
                    <span>{t(feature.name)}</span>
                  </dt>
                  <dd className="mt-1 text-base text-black-midnight-500">{t(feature.description)}</dd>
                </div>
              ))}
            </dl>
          </div>
        ))}
      </div>
    </div>
  );
}

import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { InputHTMLAttributes, useEffect, useState } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  addInput?: () => void;
  removeIcon?: boolean;
}

function Input({
  value,
  onChange,
  addInput,
  removeIcon = false,
  onKeyDown,
}: InputProps) {
  return (
    <div className='mt-2 flex rounded-md shadow-sm h-[49px]'>
      
      <div className='relative flex flex-grow items-stretch focus-within:z-10'>
        <input
          type='email'
          name='email'
          id='email'
          className='block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-respect-500 sm:text-sm sm:leading-6'
          placeholder='john@example.com'
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      </div>
      
      <button
        type='button'
        className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        onClick={addInput}
      >
        {removeIcon ? (
          <XMarkIcon
            className='-ml-0.5 h-5 w-5 text-blue-respect-600'
            aria-hidden='true'
          />
        ) : (
          <PlusIcon
            className='-ml-0.5 h-5 w-5 text-blue-respect-600'
            aria-hidden='true'
          />
        )}
      </button>
    </div>
  );
}

function MultiInput({
  mainValue,
  setMainValue,
  splitValue = ',',
}: {
  mainValue: string;
  setMainValue: (value: string) => void;
  splitValue?: string;
}) {
  const [values, setValues] = useState<string[]>([]);
  const [latestValue, setLatestValue] = useState('');

  useEffect(() => {
    if (mainValue) {
      setValues(mainValue.split(splitValue));
    }
  }, [mainValue, splitValue]);

  function addInput() {
    const newValues = [...values];
    newValues.push(latestValue);
    setValues(newValues);
    setMainValue(newValues.join(splitValue));
    setLatestValue('');
  }

  function onRemoveInput(index: number) {
    const newValues = [...values];
    newValues.splice(index, 1);

    setMainValue(newValues.join(splitValue));
    setValues(newValues);
  }

  function onChangeValue(index: number, value: string) {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    setMainValue(newValues.join(splitValue));
  }

  function onChangeInputValue(e: React.ChangeEvent<HTMLInputElement>) {
    setLatestValue(e.target.value);
    setMainValue(e.target.value);
  }

  return (
    <div>
      <label htmlFor='email' className='sr-only'>
        Add Emails
      </label>
      <Input
        addInput={() => addInput()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            addInput();
          }
        }}
        value={latestValue}
        onChange={onChangeInputValue}
      />
      {values.map((value, index) => (
        <Input
          addInput={() => onRemoveInput(index)}
          value={value}
          key={index}
          removeIcon={true}
          onChange={(e) => onChangeValue(index, e.target.value)}
        />
      ))}
    </div>
  );
}

export default MultiInput;

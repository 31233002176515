import { useContext, useEffect, useState } from 'react';
import { Button } from '@urbansportsclub/components/Button';
import { UserContext, UserRole } from 'contexts/UserContext';
import { getFromLocalStorage, saveToLocalStorage, saveToLocalStorageWithExpiry } from 'utils/localStorage';
import { CompanyContext } from 'contexts/CompanyContext';
import API from 'utils/api';
import { IUserRolesSelection } from './UserRolesSelection.types';
import { useTranslation } from 'react-i18next';
import UserRolesHeader from './components/UserRolesHeader';
import UserRolesCards from './components/UserRolesCards';
import UserRolesWarning from './components/UserRolesWarning';
import UserRolesActions from './components/UserRolesActions';
import UserRolesConfirmModal from './components/UserRolesConfirmModal';
import { TrackOnItemClickedEvent, TrackOnMayBeLaterButtonClick, TrackOnPickARoleButtonClick } from '../ManageTeam/ManageTeamTracking';
import { useAuthorize } from 'services/authorizeService';
import { useNavigate } from 'react-router-dom';

export default function UserRolesSelection({
  isModal = false,
  reload,
} :IUserRolesSelection ) {
  const { canViewHome } = useAuthorize();
  const { user, setUserRole } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedRole, setSelectedRole] = useState<UserRole>(UserRole.UNDEFINED);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (user?.role === UserRole.UNDEFINED) {
      const role = getFromLocalStorage<any>('role');

      if (!role) {
        setIsVisible(true);
      }

      if (role && role.expiry && role.userId === user?.id) {
        const now = new Date();

        if (now >= role.expiry) {
          setIsVisible(true);
        }
      }      
    }
  }, [user]);

  const handleRoleChange = (role: UserRole) => {
    setSelectedRole(role);
    TrackOnItemClickedEvent(role.toString());
  };

  const handleMaybeLater = () => {
    saveToLocalStorageWithExpiry('role', UserRole.UNDEFINED, 2, user?.id);
    setIsVisible(false);
    TrackOnMayBeLaterButtonClick();
  };

  const refreshTheToken = () => {
    const refreshToken = getFromLocalStorage(process.env.REACT_APP_REFRESH_TOKEN as string);

    API.post(
      '/token/refresh', {
        refresh_token: refreshToken,
      },
    ).then((res: any) => {
      saveToLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string, res.data.token);
      saveToLocalStorage(process.env.REACT_APP_REFRESH_TOKEN as string, res.data.refresh_token);
    });
  };

  const handleRolePick = () => {
    API.post(
      `companies/${company?.id}/users/${user?.id}/assign-role`, {
        role: selectedRole,
      },
    ).then(data => {
      saveToLocalStorageWithExpiry('role', selectedRole, 2, user?.id);
      setIsVisible(false);
      setIsConfirmModalVisible(true);
      setUserRole(selectedRole);
      refreshTheToken();
      TrackOnPickARoleButtonClick(isModal ? 'Employee' : 'Account');
      reload?.();
      if ( isModal && canViewHome()) {
        navigate('/account');
      }
    });
  };

  const renderModal = () => {
    const roleTitle = t(`user_roles.${selectedRole.replace('ROLE_', '').toLowerCase()}.title`);

    if (isConfirmModalVisible) {
      return <UserRolesConfirmModal selectedRoleTitle={roleTitle} onClick={() => setIsConfirmModalVisible(false)}/>;
    }

    if (!isVisible) return <></>;

    return (
      <>
        <div className='fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity w-screen'>
          <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 box-border max-w-[calc(100vw-32px)] w-[100%] p-6 sm:w-auto md:relative md:bottom-auto md:left-auto md:top-1/2 md:-translate-x-0 md:-translate-y-[60%] md:box-content md:max-w-[598px] md:p-8 z-20 overflow-auto max-h-[100vh] bg-white rounded-lg mx-auto'>
            <UserRolesHeader />
            <UserRolesCards selectedRole={selectedRole} handleRoleChange={handleRoleChange} />
            <UserRolesWarning selectedRole={selectedRole} />
            <UserRolesActions
              selectedRole={selectedRole}
              submitBtnText={selectedRole !== UserRole.UNDEFINED ? `${t('user_roles.continue_as')} ${roleTitle}` : t('user_roles.actions.confirm')}
              handleRolePick={handleRolePick}
              handleMaybeLater={handleMaybeLater}
            />
          </div>
        </div>
      </>
    );
  };

  const renderBlock = () => {
    if (user?.role !== UserRole.UNDEFINED) return <></>;

    const roleTitle = t(`user_roles.${selectedRole.replace('ROLE_', '').toLowerCase()}.title`);

    return (
      <>
        <div className='border border-solid border-grey-dark-200 p-6 md:p-8 mt-4 bg-white rounded-lg box-content mx-auto max-w-[calc(100vw-32px)] sm:max-w-[1040px] lg:max-w-[2040px]'>
          <UserRolesHeader />
          <UserRolesCards isFullWidth selectedRole={selectedRole} handleRoleChange={handleRoleChange} />
          <UserRolesWarning selectedRole={selectedRole} />
          <UserRolesActions
            showSingleButton
            selectedRole={selectedRole}
            submitBtnText={selectedRole !== UserRole.UNDEFINED ? `${t('user_roles.continue_as')} ${roleTitle}` : t('user_roles.actions.confirm')}
            handleRolePick={handleRolePick}
          />
        </div>
      </>
    );
  };

  return isModal ? renderModal() : renderBlock();
}

import MarketingBox from './MarketingBox';
import { Accordion } from '@urbansportsclub/components/Accordion';
import { AccordionSummary } from '@urbansportsclub/components/Accordion/Summary';
import { AccordionDetails } from '@urbansportsclub/components/Accordion/Details';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Text } from '@urbansportsclub/components/Text';
import { Divider } from 'components/atoms/Tailwind/Divider/Divider';
import { useContext, useState } from 'react';
import { CompanyContext } from 'contexts/CompanyContext';
import { useTranslation } from 'react-i18next';
import { AccordianClickedEvent } from './MarketingTracking';
import * as styles from './MarketingDiv.variants';

export interface IMarketingDivProps {
  defaultExpanded?: boolean;
  addSlack?: boolean;
  textKey: string;
  dataLayerKeyTitle: string;
}

export default function MarketingDiv({
  defaultExpanded = false,
  addSlack = false,
  textKey,
  dataLayerKeyTitle,
}: IMarketingDivProps) {
  const [showDivider, setShowDivider] = useState(defaultExpanded);
  const { company } = useContext(CompanyContext);
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const { t } = useTranslation();

  function getEmailBody() {
    return t(`marketing.${textKey}.email`)
      .replaceAll('\\n', '')
      .replaceAll('{company}', company?.name || '')
      .replaceAll('{landingPage}', company?.employeeSignUpUrl || '');
  }

  function getSubject() {
    return t(`marketing.${textKey}.subject`).replaceAll(
      '{company}',
      company?.name || '',
    );
  }

  function getSlackText() {
    return t(`marketing.${textKey}.slack`)
      .replaceAll('\\n', '')
      .replaceAll('{company}', company?.name || '')
      .replaceAll('{landingPage}', company?.employeeSignUpUrl || '');
  }

  return (
    <>
      <Accordion
        className="bg-white border border-grey-natural-500 rounded-md px-5 py-2.5"
        defaultExpanded={defaultExpanded}
        onChange={(e, expanded) => {
          AccordianClickedEvent(expanded ?  'Open' : 'Close', dataLayerKeyTitle);
          setShowDivider(!showDivider);
          setIsExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={
            <ChevronDownIcon className={'text-grey-dark-500 h-auto w-6 h-6'} />
          }
          className={styles.AccordionSummary({ expanded: isExpanded })}
        >
          <div className="flex flex-col space-y-1 w-full">
            <Text intent="md" as="span" weight="bold" className="text-base">
              {t(`marketing.${textKey}.title`)}
            </Text>
            <Text intent="md" as="span" className="text-base text-wrap">
              {t(`marketing.${textKey}.description`)}
            </Text>
          </div>
        </AccordionSummary>
        {showDivider && (
          <div className="-mt-2 mb-2">
            <Divider />
          </div>
        )}
        <AccordionDetails>
          <div className="flex flex-col pt-3 space-y-4">
            <MarketingBox
              datalayerFieldKey="subject"
              heading={t('marketing.title.subject')}
              body={getSubject()}
              removeAdditionalSpace
              fixedHeight
              datalayerMarketingType={dataLayerKeyTitle}
            />
            <MarketingBox
              datalayerFieldKey="email"
              heading={t('marketing.title.email')}
              body={getEmailBody()}
              removeAdditionalSpace
              datalayerMarketingType={dataLayerKeyTitle}
            />
            {addSlack && (
              <MarketingBox
                datalayerFieldKey="Slack Whatsapp Message"
                heading={t('marketing.title.external_communication')}
                body={getSlackText()}
                datalayerMarketingType={dataLayerKeyTitle}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

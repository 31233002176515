/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import Label from 'components/atoms/Label';
import Input, { Props as InputProps } from 'components/atoms/Input';

import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye-open.svg';
import { ReactComponent as EyeCrossedIcon } from 'assets/icons/eye-crossed.svg';

import './FormField.scss';

interface Props extends InputProps {
  label?: string;
  error?: FieldError;
  message?: string;
}

const FormField = ({
  label = '',
  error,
  message,
  ...inputProps
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { name, type } = inputProps;
  const [currentType, setCurrentType] = useState(type);

  const onShowHidePassword = () => {
    setCurrentType(currentType === 'password' ? 'text' : 'password');
  };

  return (
    <Label htmlFor={name} text={label} className="form-field">
      <Input {...inputProps} type={currentType} />
      {type === 'password' && (
        <button type="button" className="form-field__show-hide" onClick={onShowHidePassword}>
          {currentType === 'text' ? <EyeCrossedIcon /> : <EyeOpenIcon />}
        </button>
      )}
      {error && <span className="form-field__error">{t(`forms.${type}.errors.${error.type}`)}</span>}
      {(message && !error) && <span className="form-field__message">{message}</span>}
    </Label>
  );
};

const ForwardRefFormField = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => (
  <FormField {...props} refs={ref} />
));

export default ForwardRefFormField;

import { Button } from '@urbansportsclub/components/Button';
import { useTranslation } from 'react-i18next';

export default function UserRolesConfirmModal({
  selectedRoleTitle,
  onClick,
} : {
  selectedRoleTitle: string | undefined;
  onClick: () => void;
}) {
  const { t } = useTranslation();
 
  return (
    <>
      <div className='fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity w-screen overflow-y-auto'>
        <div className='z-20 relative top-1/2 transform -translate-y-[60%] bg-white rounded-lg p-8 box-content max-w-[360px] mx-auto'>
        <div className='text-center mb-6'>
          <span className='text-xl font-semibold mb-2'>{`${t('user_roles.final_modal.title')} — ${selectedRoleTitle}!`}</span>
          <div className='items-center text-lg tracking-wide'>
            {t('user_roles.final_modal.description')}
          </div>
        </div>
        <Button fullWidth className='text-base' onClick={onClick}>{t('user_roles.final_modal.cta_button')}</Button>
        </div>
      </div>
    </>
  );
}

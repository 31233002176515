import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function UserRolesHeader() {
  const { t } = useTranslation();

  return (
    <div className='text-left md:text-center mb-[18px] md:mb-4 flex flex-col -mt-1 sm:-mt-2 md:items-center'>
      <span className='text-xl font-semibold'>{t('user_roles.title')}</span>
      <div className='items-center text-sm md:text-base tracking-wide max-w-[700px]'>
        {`${t('user_roles.description.text')} `}
        {/* TODO: uncomment this block when we have the HREF of the link
        <Link to={t('user_roles.description.link_href')} className='text-sm md:text-base text-blue-respect-500 underline cursor-pointer'>
          {t('user_roles.description.link_title')}
        </Link>
        */}
      </div>
    </div>
  );
}

import { DocumentDuplicateIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

import { Text } from '@urbansportsclub/components/Text';
import { Button } from '@urbansportsclub/components/Button';
import { useCallback, useState } from 'react';

import * as styles from './MarketingBox.variants';
import { useTranslation } from 'react-i18next';
import { CopyButtonClickedEvent, CopyElementClickedEvent } from './MarketingTracking';
import { useAppContext } from 'context/AppContext';

export default function MarketingBox({
  heading,
  body,
  removeAdditionalSpace = false,
  fixedHeight = false,
  datalayerFieldKey,
  datalayerMarketingType,
}: {
  heading: string;
  body: string;
  removeAdditionalSpace?: boolean;
  fixedHeight?: boolean;
  datalayerFieldKey: string;
  datalayerMarketingType: string;
}) {
  
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  const { language } = useAppContext();  

  const copyButtonEvent = (div: boolean): void => {
    !div
      ? CopyButtonClickedEvent(datalayerFieldKey, datalayerMarketingType)
      : CopyElementClickedEvent(datalayerFieldKey, datalayerMarketingType);
  };

  const onCopyClickHandlerHtml = useCallback((text: string, div = false): void => {
    const richText: string = text;
    let plainText: string | undefined = text
      .replaceAll(/<br \/><br \/>/g, '\n')
      .replaceAll(/<br \/>/g, '\n')
      .replace(/<ul(.*)>/g, '\n')
      .replace(/<li>/g, '• ')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/ul>/g, '\n')
      .replace(/\n{2,}/g, '\n');

    if (removeAdditionalSpace) {
      plainText = plainText.replace(/\s{6,7}/g, '\n');
    }

    if (typeof ClipboardItem !== 'undefined') {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([richText || ''], { type: 'text/html' }),
          'text/plain': new Blob([plainText || ''], { type: 'text/plain/' }),
        }),
      ]);
    } else {
      // Fallback using the deprecated `document.execCommand`.
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
      const cb = (e: ClipboardEvent) => {
        if (!e.clipboardData) {
          return;
        }

        e.clipboardData.setData('text/html', richText);
        e.clipboardData.setData('text/plain', plainText);
        e.preventDefault();
      };
      document.addEventListener('copy', cb);
      document.execCommand('copy');
      document.removeEventListener('copy', cb);

    }

    
    setCopied(true);
    copyButtonEvent(div);
    toast(t('marketing.notification.copied'), {
      autoClose: 3000,
      className: 'mb-1 sm:mb-2',
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [removeAdditionalSpace, t]);


  function getCopyText() {
    return copied ? t('marketing.button.copied') : t('marketing.button.copy');
  }
  function getCopyButton(fullwidth = false, hideOnMobile = true) {
    const Icon = copied ? ClipboardDocumentCheckIcon : DocumentDuplicateIcon;

    return <Button
              variant="secondary"
              onClick={() => onCopyClickHandlerHtml(body)}
              small={!fullwidth}
              className={styles.button({ copied, hideOnMobile, isDe: language === 'de' })}
              fullWidth={fullwidth}
            >
              <Icon className="w-4 h-4" />
             {getCopyText()}{fullwidth && ' ' + t('marketing.button.copy_message')}
            </Button>;
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-1">
          <div className="flex justify-between items-center w-full">
            <Text as="span" weight="semibold" intent="sm" className="text-base tracking-[0.5px]">
              {heading}
            </Text>
           {getCopyButton()}
          </div>
          <div>
            <div
              className={styles.textBox({ fixedHeight })}
              onClick={() => onCopyClickHandlerHtml(body, true)}
            >
              <Text
                as="p"
                intent="md"
                className={styles.text()}
              >
                <>
                  {parse(
                    sanitizeHtml(body, {
                      allowedClasses: {
                        ul: ['list-disc', 'ml-8', 'mb-2', 'ml-6', 'sm:ml-8'],
                      },
                    }),
                  )}
                </>
              </Text>
            </div>
          </div>
          <div className='min-w-[244px] sm:hidden'>
            {getCopyButton(true, false)}
          </div>
        </div>
      </div>
    </>
  );
}

import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import TermsAndConditions from 'components/organisms/TermsAndConditions/TermsAndConditions';
import { MARKETING } from 'utils/constants';
import MarketingDiv, { IMarketingDivProps } from './MarketingDiv';
import { useTranslation } from 'react-i18next';

const marketingContent: IMarketingDivProps[] = [
  {
    textKey: 'welcome',
    defaultExpanded: true,
    addSlack: true,
    dataLayerKeyTitle: 'Welcome Email',
  },
  {
    textKey: 'signup',
    defaultExpanded: false,
    addSlack: false,
    dataLayerKeyTitle: 'Signup Email',
  },
  {
    textKey: 'activation',
    defaultExpanded: false,
    addSlack: false,
    dataLayerKeyTitle: 'Activation Email',
  },
];

function MarketingHub() {
  const { t } = useTranslation();
  return (
    <SidebarLayout heading={t('menu.marketing_hub')} showDownloadList={false} >
      <div className="flex flex-col w-full -mt-5">
        <div className="flex w-full border-r-0 border-b border-grey-natural-500">
          <Tabs
            defaultOption="Message Template"
            variant="primary"
            mode="light"
            className="border-0 hidden sm:flex"
          >
            <TabsItem value="Message Template">{t('marketing.tab.message')}</TabsItem>
          </Tabs>
          <span className='hidden sm:block relative py-4 px-4 text-base font-normal whitespace-nowrap text-black-midnight-light-500/40'>
            {t('marketing.tab.coming_soon')}
          </span>
        </div>
        <div className='mt-8 flex flex-col space-y-4'>
          {marketingContent.map((content) =>
            <MarketingDiv
              key={content.textKey}
              defaultExpanded={content.defaultExpanded}
              textKey={content.textKey}
              addSlack={content.addSlack} 
              dataLayerKeyTitle={content.dataLayerKeyTitle}/>)
          }
        </div>
      </div>
      {/* <TermsAndConditions page={MARKETING} isBackgroundDisabled isShrinked /> */}
    </SidebarLayout>
  );
}

export default MarketingHub;

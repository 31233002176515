import { pushToButtonDataLayer, pushToDataLayer } from 'utils/dataLayer';

export const AccordianClickedEvent = (action:string, details:string) => {
  pushToButtonDataLayer({
    item: action + ' Container',
    details,
    origin: 'Marketing Hub',
  });
};

export const CopyButtonClickedEvent = (details:string, marketingType: string) => {
  pushToButtonDataLayer({
    item: 'Copy Button',
    details,
    origin: 'Marketing Hub ' + marketingType,
  });
};
export const CopyElementClickedEvent = ( details:string, marketingType: string) => {
  pushToDataLayer({
    event: 'element_clicked',
    item: 'Copy Text',
    details,
    origin: 'Marketing Hub ' + marketingType,
  });
};
import { useTranslation } from 'react-i18next';
import RoleCard from 'components/molecules/RoleCard/RoleCard';
import { FlagIcon, UserIcon, CurrencyEuroIcon } from '@heroicons/react/16/solid';
import { UserRole } from 'contexts/UserContext';
import * as styles from '../UserRolesSelection.variants';
import * as stylesRoleCard from './UserRoleCard.variants';

export default function UserRolesCards({
  isFullWidth = false,
  selectedRole,
  handleRoleChange,
} : {
  isFullWidth?: boolean;
  selectedRole: UserRole;
  handleRoleChange: (role: UserRole) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardsContainerDiv({ isFullWidth })}>
      <RoleCard
        Icon={<FlagIcon className={stylesRoleCard.icon({ isFullWidth, className: 'text-purple-community-500' })} />}
        iconBg='bg-purple-community-50'
        title={t('user_roles.admin.title')}
        description={t('user_roles.admin.description')}
        isSelected={selectedRole === UserRole.Admin}
        onClick={() => handleRoleChange(UserRole.Admin)}
        isFullWidth={isFullWidth}
      />
      <RoleCard
        Icon={<UserIcon className={stylesRoleCard.icon({ isFullWidth, className: 'text-blue-loyalty-500' })} />}
        iconBg='bg-blue-loyalty-50'
        title={t('user_roles.hr.title')}
        description={t('user_roles.hr.description')}
        isSelected={selectedRole === UserRole.HR}
        onClick={() => handleRoleChange(UserRole.HR)}
        isFullWidth={isFullWidth}
      />
      <RoleCard
        Icon={<CurrencyEuroIcon className={stylesRoleCard.icon({ isFullWidth, className: 'text-green-freedom-500' })} />}
        iconBg='bg-green-freedom-50'
        title={t('user_roles.finance.title')}
        description={t('user_roles.finance.description')}
        isSelected={selectedRole === UserRole.Finance}
        onClick={() => handleRoleChange(UserRole.Finance)}
        isFullWidth={isFullWidth}
      />
    </div>
  );
}

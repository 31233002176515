import axios, { AxiosResponse } from 'axios';

import { getFromLocalStorage, saveToLocalStorage, clearLocalStorage } from 'utils/localStorage';
import { getCustomError, CustomError } from 'utils/api/error';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getAuthRequestConfig, requestConfig } from 'utils/api/requestConfig';

interface AuthTokenResponse {
  token: string,
  refresh_token: string,
}

export const isTokenValid = (): boolean => {
  const token = getFromLocalStorage(
    process.env.REACT_APP_AUTH_TOKEN as string,
  ) as string;
  const decodedToken = jwtDecode<JwtPayload>(token);
  const tokenTimestamp = decodedToken.exp ?? 0;


  return tokenTimestamp > (new Date().getTime() / 1000);
};

export const isAuthenticated = (): boolean => (
  !!getFromLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string) && isTokenValid()
);

export const login = async (
  data: { email: string, password: string },
): Promise<void | CustomError> => {
  try {
    const response: AxiosResponse<AuthTokenResponse> = await axios.post(
      '/authentication-token',
      data,
      getAuthRequestConfig(),
    );
    
    saveToLocalStorage(process.env.REACT_APP_AUTH_TOKEN as string, response.data.token);
    saveToLocalStorage(process.env.REACT_APP_REFRESH_TOKEN as string, response.data.refresh_token);

    return undefined;
  } catch (err) {
    // @ts-ignore
    return getCustomError(err);
  }
};

export const logout = (): void => {
  clearLocalStorage();
  window.location.href = '/login';
};

export const requestPassword = async (
  data: { email: string },
): Promise<void | CustomError> => {
  try {
    
    const response: AxiosResponse<AuthTokenResponse> = await axios.post(
      '/users/reset-password',
      data,
      getAuthRequestConfig(),
    );
    return undefined;
  } catch (err) {
    // @ts-ignore
    return getCustomError(err);
  }
};

export const resetPassword = async (
  data: { newPassword: string; newPasswordConfirm: string; token: string; },
): Promise<void | CustomError> => {
  try {
    const response: AxiosResponse<AuthTokenResponse> = await axios.post(
      '/users/set-new-password',
      data,
      getAuthRequestConfig(),
    );
    return undefined;
  } catch (err) {
    // @ts-ignore
    return getCustomError(err, 'reset_password.errors');
  }
};
import { useState } from 'react';
import './CellItem.scss';
import Button, { ButtonSize, ButtonVariants } from 'components/atoms/Tailwind/Button';

interface Props {
  property?: string;
  value?: string;
  editable?: boolean;
  name?: string;
  onSave?: (param:any) => void
}

const CellItem = ({ property, value, editable, onSave }: Props): JSX.Element => {

  const [isEditable, setIsEditable] = useState(false);
  const [itemValue, setItemValue] = useState(value);

  function handleEdit() {
    setIsEditable(!isEditable);
  }

  function handleSave() {
    onSave && onSave(itemValue);
    setIsEditable(!isEditable);
  }

  function viewField() {
    return itemValue || '-';
  }

  function editField() {
    return (
      <input
        className='cell-item-input'
        name="name"
        type="text"
        value={itemValue}
        onChange={(e) => setItemValue(e.target.value)}
      />
    );
  }

  function getSaveButtonText() {
    return isEditable ? 'save' : 'edit';
  }

  function getEditButtonAction() {
    return isEditable ? handleSave() : handleEdit();
  }

  function editableField() {
    return isEditable ? editField() : viewField();
  }

  function getButtonType() {
    return isEditable ? ButtonVariants.PRIMARY : ButtonVariants.SECONDARY;
  }

  return (
    <div className="cell-item">
      <p>{property}</p>
      {editable ?
        <p className="secondary">
          {editableField()}
          <span className='cell-item-button'>
              <Button
                size={ButtonSize.SMALL}
                text={getSaveButtonText()}
                onClick={getEditButtonAction}
                colors={getButtonType()}
              />
            </span>
        </p> :
        viewField()
      }
    </div>
  );
};

export default CellItem;

import { tv } from 'tailwind-variants';

export const containerDiv = tv({
  base: 'cursor-pointer',
  variants: {
    isSelected: {
      true: 'border-2 border-blue-respect-500',
    },
    isFullWidth : {
      true: 'w-full h-full md:h-[106px]',
      false: 'w-full md:h-[164px] md:w-[194px]',
    },
    lang: {
      de: '',
      nl: '',
      en: '',
      pt: '',
      es: '',
      fr: '',
      it: '',
    },
  },
  compoundVariants: [
    {
      isFullWidth: false,
      lang: 'en',
      className: 'md:h-[144px]',
    },
    {
      isFullWidth: false,
      lang: 'de',
      className: 'md:h-[186px]',
    },
    {
      isFullWidth: false,
      lang: 'it',
      className: 'md:h-[144px]',
    },
    {
      isFullWidth: true,
      lang: 'de',
      className: 'md:h-[100px] md:max-lg:h-[124px]',
    },
    {
      isFullWidth: true,
      lang: 'es',
      className: 'md:h-[100px] md:max-lg:h-[124px]',
    },
    {
      isFullWidth: true,
      lang: 'pt',
      className: 'md:h-[100px] md:max-lg:h-[124px]',
    },
    {
      isFullWidth: true,
      lang: 'fr',
      className: 'md:h-[106px] md:max-lg:h-[124px]',
    },
  ],
});


export const cardsContainerDiv = tv({
  base: 'flex w-full',
  variants: {
    isFullWidth: {
      true: 'flex-row items-start space-x-4 max-[320px]:space-x-1',
      false: 'flex-row items-center space-x-4 max-[320px]:space-x-1 md:items-start md:flex-col md:space-y-2 md:space-x-0',
    },
  },
});


export const iconSVG = tv({
  base: 'flex min-w-[32px] justify-center items-center rounded-md max-[320px]:hidden',
  variants: {
    isFullWidth: {
      true: 'min-w-[48px] min-h-[48px]',
      false: 'min-w-[48px] min-h-[48px] sm:min-w-[32px] sm:min-h-[32px]',
    },
  },
});

export const radio = tv({
  base: "[&:checked]:bg-[url('data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%273%27/%3e%3c/svg%3e')] mb-3",
});

export const descriptionText = tv({
  base: 'text-sm sm:text-base text-grey-dark-700',
  variants: {
    isFullWidth: {
      false: 'md:w-[144px] md:-tracking-[0.5px]',
    },
  },
});
import { Button } from '@urbansportsclub/components/Button';
import { Checkbox } from '@urbansportsclub/components/Checkbox';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import { Text } from '@urbansportsclub/components/Text';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-black.svg';
import About from './Sections/About';
import { useContext, useEffect, useRef, useState } from 'react';
import DataManagement from './Sections/DataManagement';
import GettingStarted from './Sections/GettingStarted';
import { showKomboConnect } from '@kombo-api/connect';
import { AxiosResponse } from 'axios';
import { IGetActivationTokenResponse } from 'components/organisms/HRIntegrationCard/HRIntegrationCard';
import API from 'utils/api';
import { CompanyContext } from 'contexts/CompanyContext';
import { useAcceptTerms, useSaveHRIntegration } from 'services/companyService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HRBackToPortal, HRConnectionFailed, HRConnectNow, HrTabClicked } from 'tracking/HrTracking';
import TermsAndConditions from 'components/organisms/TermsAndConditions/TermsAndConditions';
import { HRIS } from 'utils/constants';

interface ITab {
  name: string;
  value: string;
  component: () => JSX.Element;
}
const tabs: ITab[] = [
  {
    name: 'hrwizard.tab.about',
    value: 'about-hris',
    component: About,
  },
  {
    name: 'hrwizard.tab.data_management',
    value: 'data-management',
    component: DataManagement,
  },
  {
    name: 'hrwizard.tab.getting_started',
    value: 'getting-started',
    component: GettingStarted,
  },
];


function Divider() {
  return <div className="w-full border-t border-grey-natural-500 my-7 lg:my-20" />;
}
function HRWizard() {
  const [selectedTab, setSelectedTab] = useState<string>('About HRIS');
  const [checked, setChecked] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const aboutRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef<HTMLDivElement>(null);
  const gettingStartedRef = useRef<HTMLDivElement>(null);
  const { company, setShowSyncing, setPolling } = useContext(CompanyContext);
  const { saveHRIntegration, requestFinished, hasError } = useSaveHRIntegration();
  const { acceptTerms } = useAcceptTerms();
  const [currentTab, setCurrentTab] = useState(selectedTab);
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  useEffect(() => {
    window.addEventListener('scroll', () => {
      //change tab based on scroll position
      const aboutHris = aboutRef.current?.getBoundingClientRect();
      const dataManagement = dataRef.current?.getBoundingClientRect();
      const gettingStarted = gettingStartedRef.current?.getBoundingClientRect();
      if (aboutHris && aboutHris.top < 100 && aboutHris.bottom > 100) {
        setSelectedTab(t('hrwizard.tab.about'));
      } else if (dataManagement && dataManagement.top < 100 && dataManagement.bottom > 100) {
        setSelectedTab(t('hrwizard.tab.data_management'));
      } else if (gettingStarted && gettingStarted.top < 100 && gettingStarted.bottom > 100) {
        setSelectedTab(t('hrwizard.tab.getting_started'));
      }
    });
  }, [t]);

  useEffect(() => {
    if (!hasError && requestFinished ) {
      setDisabled(false);
      setPolling(true);
      setShowSyncing(true);
      toast( t('hr_integration.toast.syncing'), {
        position: 'top-right',
      });
      navigate('/hr-success');
    }

  }, [requestFinished, hasError, t, navigate, setDisabled, setPolling, setShowSyncing]);

  useEffect(() => {
    setCurrentTab(selectedTab);
    HrTabClicked(selectedTab);
  }, [selectedTab]);
  

  function onChangeTab(value: string) {
    if (value === t('hrwizard.tab.about')) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setSelectedTab(value);
    } else if (value === t('hrwizard.tab.data_management')) {
      window.scroll({ top: (dataRef.current?.offsetTop || 40) - 130, behavior: 'smooth' });
      setSelectedTab(value);
    } else if (value === t('hrwizard.tab.getting_started')) {
      window.scroll({ top: (gettingStartedRef.current?.offsetTop || 40) - 160, behavior: 'smooth' });
      setSelectedTab(value);
    }
  }

  async function kommboConnect() { 
    try {
      HRConnectNow('HR Wizard', currentTab);
      setDisabled(true);
      const mainConnectionLink = await getIntegrationConnectionLink();
      const activationToken = await showKomboConnect(mainConnectionLink);
      saveHRIntegration(getCompanyId(), activationToken);
      acceptTerms(getCompanyId(), 'hris');
      toast( t('hr_integration.toast.syncing'), {
        position: 'top-right',
      });
      navigate('/hr-success');
    } catch (error) {
      HRConnectionFailed();
      setDisabled(false);
    }
  }

  async function getIntegrationConnectionLink(): Promise<string> {
    const response: AxiosResponse<IGetActivationTokenResponse> =
      await API.get(
        '/company/' + getCompanyId() + '/hr-integration-link',
      );
    return response.data.link;
  }

  function getCompanyId() {
    if (company) return company.id;

    return '';
  }

  return (
    <div className="flex flex-col items-baseline bg-white scroll-smooth px-4 sm:px-16">
      <div className='top-0 sticky bg-white z-10 w-full py-2 sm:py-6'>
        <div className="flex justify-between items-center py-6 sm:py-0">
            <Button variant="link" className="bg-transparent" onClick={() => {
              navigate(-1);
              HRBackToPortal();
            }}>
            <ArrowLeftIcon className="h-5 w-5" />
            {t('hrwizard.back_to_portal')}
            </Button>
            <div>
              <LogoIcon className="h-[30px] sm:h-8" />
            </div>
        </div>
        <div className="flex w-full border-r-0 border-b border-grey-natural-500 justify-between items-baseline -mt-4 sm:-mt-3">
            <Tabs
              defaultOption={currentTab}
              variant="primary"
              mode="light"
              className="border-0 hidden sm:flex"
              onChange={onChangeTab}
              >
              {tabs.map((tab) => (
                  <TabsItem key={tab.name} value={t(tab.name)}>
                  {t(tab.name)}
                  </TabsItem>
              ))}
            </Tabs>
            <span className="text-base text-black-midnight-500 hidden md:block">
            {t('hrwizard.tab_end')}
            </span>
        </div>
      </div>
      <div className='bg-white w-full'>
        <div id='about-hris' ref={aboutRef} className='pt-8'>
            <About />
        </div>
        <Divider />
        <div id='data-management' ref={dataRef}>
            <DataManagement />
        </div>
        <Divider />
        <div id='getting-started' ref={gettingStartedRef} className='mb-[200px] md:mb-[140px]'>
            <GettingStarted />
        </div>
      </div>
      <TermsAndConditions page={HRIS} onClick={kommboConnect} isDisabled={disabled} />
    </div>
  );
}

export default HRWizard;

import { tv } from 'tailwind-variants';


export const menuItem = tv({
  base: 'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 no-underline hove:no-underline cursor-pointer',
  variants: {
    active: {
      true: 'bg-gray-800 text-white',
      false: 'text-gray-400 hover:bg-gray-800 hover:text-white',
    },
  },
  defaultVariants: {
    active: false,
  },
});
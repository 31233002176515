import { tv } from 'tailwind-variants';


export const icon = tv({
  variants: {
    isFullWidth: {
      false: 'w-6 h-6 sm:w-5 sm:h-5',
      true: 'w-6 h-6',
    },
  },
});
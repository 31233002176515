import { tv } from 'tailwind-variants';


export const divInput = tv({
  base: 'relative flex flex-grow items-stretch focus-within:z-10 h-[49px]',
});

export const inputIcon = tv({
  base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
});

export const input = tv({
  base: 'block w-full rounded-md border-0 py-1.5 text-grey-dark-900 ring-1 ring-inset ring-grey-dark-300 placeholder:text-grey-dark-400 focus:ring-2 focus:ring-inset focus:ring-blue-respect-600 sm:text-sm sm:leading-6',
  variants: {
    hasIcon: {
      true: 'pl-10',
    },
  },
});
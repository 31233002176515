import { Dropdown } from '@urbansportsclub/components/Dropdown';
import { DropdownContent } from '@urbansportsclub/components/Dropdown/Content';
import { DropdownItem } from '@urbansportsclub/components/Dropdown/Item';
import { DropdownTrigger } from '@urbansportsclub/components/Dropdown/Trigger';
import { CompanyContext } from 'contexts/CompanyContext';
import { UserContext, UserRole } from 'contexts/UserContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorize } from 'services/authorizeService';
import { toast } from 'react-toastify';
import API from 'utils/api';
import { TrackOnDropDownSelectionEvent } from './ManageTeamTracking';

interface Role {
  id: string;
  value: string;
}

const SetUserRole = ({
  incomingRole,
  userId,
}: {
  incomingRole: string;
  userId: string;
}) => {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { canChangeUserRole } = useAuthorize();

  const [selectedRole, setSelectedRole] = useState<string>(incomingRole);
  const [disabled, setDisabled] = useState(!canChangeUserRole());
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.id === userId) {
      setDisabled(true);
    }
  }, [user, userId]);

  useEffect(() => {
    setSelectedRole(incomingRole);
  }, [incomingRole]);
  
  const getRoles: Role[] = useMemo(() => {
    return [
      {
        id: 'admin',
        value: UserRole.Admin,
      },
      {
        id: 'finance',
        value: UserRole.Finance,
      },
      {
        id: 'hr',
        value: UserRole.HR,
      },
    ];
  }, []);

  const getSelectedRole = useCallback(() => {
    if (selectedRole === UserRole.UNDEFINED) {
      return 'unassigned';
    }

    if (selectedRole === UserRole.SuperAdmin) {
      return 'super_admin';
    }

    return getRoles.find((role) => role.value === selectedRole)?.id || '';
  }, [getRoles, selectedRole]);

  const getRoleId = useCallback((roleToFind:string) => { 
    return getRoles.find((role) => role.value === roleToFind)?.id || 'unassigned';
  }, [getRoles]);
  
  const assignRole = useCallback(async (role:string) => {
    try {
      setDisabled(true);
      await API.post(`/companies/${company?.id}/users/${userId}/assign-role`, {
        role,
      });
      setDisabled(false);
      toast(t('user_role.notification.role_changed')
        .replace('{from}', t('user_role.' + getRoleId(incomingRole)))
        .replace('{to}', t('user_role.' + getRoleId(role))));
      TrackOnDropDownSelectionEvent(getRoleId(role));
    } catch (error) {}
  }, [company?.id, userId, t, getRoleId, incomingRole]);

  const onChange = useCallback(
    (role: string) => {
      setSelectedRole(role);
      assignRole(role);
    },
    [assignRole],
  );

  return (
    <Dropdown
      value={selectedRole}
      variant="combobox"
      mode="light"
      className="min-w-40"
      onChange={onChange}
      disabled={disabled}
    >
      <DropdownTrigger className="w-full inline-flex justify-between items-center text-left py-1 px-2 disabled:cursor-not-allowed">
        {t('user_role.' + getSelectedRole())}
      </DropdownTrigger>
      <DropdownContent className="z-10 content-stretch justify-start">
        {getRoles.map((role) => (
          <DropdownItem key={role.id} value={role.value}>
            {t('user_role.' + role.id)}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default SetUserRole;

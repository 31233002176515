import { tv } from 'tailwind-variants';

export const button = tv({
  // base: 'sm:w-[104px]',
  variants: {
    hideOnMobile: {
      true: 'mb-2 hidden sm:flex',
      false: 'flex mt-2 sm:hidden',
    },
    copied: {
      true: 'border-[#2EBA88] text-[#2EBA88] hover:bg-green-freedom-50 active:bg-green-freedom-100',
      false: 'sm:-ml-2',
    },
    isDe : {
      true: 'sm:w-[104px]',
      false: 'sm:w-[96px]',
    },
  },
});


export const textBox = tv({
  base: 'flex items-center bg-grey-light-500 border border-grey-dark-500 rounded-md text-lg text-wrap min-w-[244px] h-full py-3 px-4 md:min-w-[460px] lg:min-w-[502px] cursor-pointer',
  variants: {
    fixedHeight: {
      true: 'min-h-10 max-h-10',
    },
  },
});

export const text = tv({
  base: 'text-base text-black-midnight-light-500 break-anywhere sm:max-w-none text-pretty',
});
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Interweave } from 'interweave';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import Form, { FormMethod } from 'components/atoms/Form';
import FormField from 'components/molecules/FormField';
import Button from 'components/atoms/Tailwind/Button';
import Separator from 'components/atoms/Separator';

import { isAuthenticated, login, resetPassword } from 'services/authService';
import { DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent } from 'utils/dataLayer';
import { AuthFlow } from 'utils/constants';
import { resetPasswordFailedEvent } from 'utils/tracking';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  passwordResetConfirm: { event_group: EVENT_GROUP_NAME, event_name: 'password_reset_confirmed' },
  registrationView: { event_group: EVENT_GROUP_NAME, event_name: 'registration_viewed' },
  registrationStarted: { event_group: EVENT_GROUP_NAME, event_name: 'registration_started' },
  registrationFailure: { event_group: EVENT_GROUP_NAME, event_name: 'registration_failed' },
  registrationSuccess: { event_group: EVENT_GROUP_NAME, event_name: 'registration_succeeded' },
  passwordResetFailed: { event_group: EVENT_GROUP_NAME, event_name: 'password_reset_failed' },
};

interface FormData {
  newPassword: string;
  newPasswordConfirm: string;
}

const ResetPassword = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [formError, setFormError] = useState('');

  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const flow = searchParams.get('flow');

  useEffect(() => {
    pushDataLayerEvent(
      flow === AuthFlow.REGISTER
        ? DATA_LAYER_EVENTS.registrationView : DATA_LAYER_EVENTS.passwordResetConfirm,
    );
  }, [flow]);

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    watch,
  } = useForm<FormData>({ mode: 'all' });

  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setFormError('');

    const requestData = { ...data, token: token || '' };

    const response = await resetPassword(requestData);
    

    if (response && response.code !== 200) {
      setFormError(response.message);

      resetPasswordFailedEvent(response, flow);
      setIsLoading(false);
      return;
    }

    if (flow === AuthFlow.REGISTER) {
      pushDataLayerEvent(
        DATA_LAYER_EVENTS.registrationSuccess,
      );
    }

    const email = searchParams.get('email');
    if (email) {
      onLogin(email, data);
    } else {
      navigate(`/forgot-password/success?flow=${flow || ''}`);
    }
    
  };

  const onLogin = async (email: string, data: FormData) => {
    await login({ email, password: data.newPassword });
    if (isAuthenticated()) {
      navigate('/choose-company');
    }
  };

  const onFieldChange = () => {
    if (!isTouched && flow === AuthFlow.REGISTER) {
      setIsTouched(true);
      pushDataLayerEvent(DATA_LAYER_EVENTS.registrationStarted);
    }
  };

  return (
    <SingleColumnLayout>
      <SimpleSection title={t('reset_password.title')}>
        <p>{t('reset_password.description')}</p>
        <Form method={FormMethod.POST} onSubmit={handleSubmit(onSubmit)} error={<Interweave content={formError} />}>
          <FormField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('newPassword', { required: true, minLength: 8 })}
            name="newPassword"
            type="password"
            placeholder={t('forms.password.placeholder')}
            error={errors.newPassword}
            onKeyPress={onFieldChange}
            message={t('forms.password.message')}
          />
          <FormField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('newPasswordConfirm', {
              required: true,
              validate: (value) => value === newPassword.current,
            })}
            name="newPasswordConfirm"
            type="password"
            placeholder={t('forms.password.confirm_placeholder')}
            onKeyPress={onFieldChange}
            error={errors.newPasswordConfirm}
          />
          <Separator offset={24} />
          { flow === AuthFlow.REGISTER
            && (
            <p className="sub">
              <Interweave content={t('reset_password.terms_text')} />
            </p>
            )}
          <Button 
            text={t('reset_password.submit_button')} 
            fullWidth type='submit' 
            disabled={!isValid} 
            isLoading={isLoading} 
          />
        </Form>
      </SimpleSection>
      {flow === AuthFlow.REGISTER && (
        <strong className="single-column-layout__link">
          <Link to="/login">{t('reset_password.login_link')}</Link>
        </strong>
      )}
    </SingleColumnLayout>
  );
};

export default ResetPassword;

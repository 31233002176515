interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  showLabel?: boolean;
}
function TextArea({ label, showLabel, ...props }: ITextAreaProps) {
  return (
    <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-respect-500 bg-white">
      <label htmlFor={props.id} className="sr-only">
        {label}
      </label>
      <textarea
        className="block w-full h-[150px] resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
}

export default TextArea;

import { UserRole } from 'contexts/UserContext';
import { Button } from '@urbansportsclub/components/Button';
import { useTranslation } from 'react-i18next';

export default function UserRolesActions({
  showSingleButton = false,
  selectedRole,
  submitBtnText,
  handleRolePick,
  handleMaybeLater,
} : {
  showSingleButton?: boolean;
  selectedRole: UserRole;
  submitBtnText: string;
  handleRolePick: () => void;
  handleMaybeLater?: () => void;
}) {
  const { t } = useTranslation();

  if (showSingleButton) {
    return (
      <div className='flex justify-center mt-6'>
        <Button
          variant='secondary'
          disabled={selectedRole === UserRole.UNDEFINED}
          className='text-base w-[100%] md:w-auto'
          onClick={handleRolePick}
        >
            {submitBtnText}
        </Button>
      </div>
    );
  }

  return (
    <div className='flex flex-col-reverse md:flex-row gap-x-3 gap-y-3 mt-6 w-full'>
      <Button fullWidth variant='secondary' className='text-base' onClick={handleMaybeLater}>{t('user_roles.actions.cancel')}</Button>
      <Button fullWidth disabled={selectedRole === UserRole.UNDEFINED} className='text-base' onClick={handleRolePick}>{submitBtnText}</Button>
    </div>
  );
}

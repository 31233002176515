import { IInputProps } from './Input.types';
import * as styles from './Input.variants';

export default function Input({ icon, className, placeholder, id, type, name, onChange, onKeyDown, value } : IInputProps) {
  return <div className={styles.divInput()}>
          {icon && <div className={styles.inputIcon()}>
            {icon}
          </div>}
          <input
            type={type}
            name={name}
            id={id}
            className={styles.input({ className, hasIcon: icon !== undefined })}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
          />
    </div>;
}
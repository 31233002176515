import Reactivation, { Confirmation } from 'components/pages/Reactivation';
import { RouterProps } from 'routes';

const DevRoutes: RouterProps[] = [
  {
    path: '/reactivation',
    component: Reactivation,
  },
  {
    path: '/reactivation/confirmation',
    component: Confirmation,
  },
];

export default DevRoutes;
import * as styles from './SelectBoxes.style';

function SelectBox({
  text,
  onSelect,
  selected,
}: {
  text: string;
  onSelect: (v: string) => void;
  selected: boolean;
}) {
  return (
    <div
      onClick={() => onSelect(text)}
      className={styles.styleBox({ selected })}
    >
      <span className={styles.styleBoxText({})}>
        {text.trim()}
      </span>
    </div>
  );
}

export function SelectBoxes({
  options,
  onSelect,
  selected,
}: {
  options: string[];
  onSelect: (v: string) => void;
  selected: string;
}) {
  return (
    <div className="flex flex-wrap space-y-2 sm:w-[600px] items-baseline">
      {options.map((option, i) => (
        <div className={i % 2 === 1 ? 'sm:ml-2' : ''}>
          <SelectBox
            key={option}
            text={option}
            onSelect={onSelect}
            selected={option === selected}
          />
        </div>
      ))}
    </div>
  );
}

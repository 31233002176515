import { tv } from 'tailwind-variants';


export const AccordionSummary = tv({
  base: 'border-0 py-1',
  variants: {
    expanded: {
      true: 'mb-2',
    },
  },
});
import { useState, useRef, useEffect, useContext } from 'react';
import { useGetCompany } from 'services/companyService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Separator from 'components/atoms/Separator';
import Popup, { PopupSize } from 'components/molecules/Popup';
import Button, { ButtonSize, ButtonType } from 'components/atoms/Button';
import CustomButton from 'components/atoms/Tailwind/Button';
import chosenCompanyVar from 'utils/graphql/localState/chosenCompany';
import { formatDate } from 'utils/date';
import './Account.scss';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { CompanyContext } from 'contexts/CompanyContext';
import { ManageTeam } from 'components/organisms/ManageTeam/ManageTeam';
import HRIntegration from 'components/organisms/HRIntegration/HRIntegration';
import { useAuthorize } from 'services/authorizeService';
import { Radio } from '@urbansportsclub/components/Radio';

const copyButtonClickedEvent: ButtonLayerEvent = {
  item: 'Copy Company Link',
  details: 'Add Employee',
  origin: 'Account',
};

const buttonAddEployeeClickedEvent: ButtonLayerEvent = {
  item: 'Copy Company Link',
  details: '',
  origin: 'Account',
};

const Account = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const chosenCompany = chosenCompanyVar.init() ?? chosenCompanyVar.restore();
  const copyContainer = useRef(null);
  const utmCode = '?utm_source=portal&utm_medium=link&utm_campaign=GLOBAL-ALL-B2B-PTL-PRS-EMPL_INV';
  const { data, getCompany, getCompanyLoading } = useGetCompany();
  const urlWithUtm = `${String(data?.company.employeeSignUpUrl)}${utmCode}`;
  const { showSyncing } = useContext(CompanyContext);
  const { canCreateHRIntegration } = useAuthorize();

  useEffect(() => {
    chosenCompany && getCompany(chosenCompany.uuid);
  }, [chosenCompany, showSyncing === false ]);
  

  const onCopyClickHandler = (): void => {
    const textarea = document.createElement('textarea');

    textarea.textContent = urlWithUtm;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();

    pushToButtonDataLayer(copyButtonClickedEvent);

    try {
      navigator.clipboard.writeText(urlWithUtm);
      toast(t('account_page.popup.copy_toast'));
    } catch (ex) {
      toast(t('account_page.popup.copy_fail_toast'));
    } finally {
      document.body.removeChild(textarea);
    }
  };

  const onModalButtonClickHandler = (): void => {
    setIsOpen(true);
    pushToButtonDataLayer(buttonAddEployeeClickedEvent);
  };

  

  function reloadCompany() {
    chosenCompany && getCompany(chosenCompany.uuid);
  }

  return (
    <>
      <SidebarLayout
        heading={t('account_page.title')}
        loading={getCompanyLoading}
        headerButtonText={t('account_page.invite_button')}
        headerButtonOnClick={onModalButtonClickHandler}
      >
        <div className="pt-8">
          <section className="info-section">
            <h2 className="info-section__title heading-5">
              {t('account_page.company.title')}
            </h2>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.company.name')}
              </span>
              <span className="info-section__value">
                {data?.company.name || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.company.start_of_cooperation')}
              </span>
              <span className="info-section__value">
                {(data?.company.cooperationStartDate &&
                  formatDate(new Date(data?.company.cooperationStartDate))) ||
                  '-'}
              </span>
            </div>
          </section>
          {canCreateHRIntegration() && data && data?.company.featureFlags.isHrIntegrationEnabled && (
            <>
              <Separator marginBottom={40} />
              <HRIntegration company={data?.company} reloadCompany={reloadCompany}/>
            </>
          )}
          {data?.company.featureFlags.isUserManagementEnabled && (
            <>
              <Separator marginBottom={40} />
              <ManageTeam />
            </>
          )}
          <Separator marginBottom={40} />
          <section className="info-section">
            <h2 className="info-section__title heading-5">
              {t('account_page.domain.title')}
            </h2>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.domain.website')}
              </span>
              <span className="info-section__value">
                {data?.company.websiteUrl || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.domain.email_domain')}
              </span>
              <span className="info-section__value">
                {data?.company.emailDomain || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.domain.signup_link')}
              </span>
              <span className="info-section__value">
                {data?.company.employeeSignUpUrl ? (
                  <a href={data?.company.employeeSignUpUrl}>
                    {data?.company.employeeSignUpUrl}
                  </a>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </section>
          <Separator marginBottom={40} />
          <section className="info-section">
            <h2 className="info-section__title heading-5">
              {t('account_page.company_contact.title')}
            </h2>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.company_contact.name')}
              </span>
              <span className="info-section__value">
                {data?.company.contactName || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.company_contact.email')}
              </span>
              <span className="info-section__value">
                {data?.company.contactEmail || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.company_contact.phone')}
              </span>
              <span className="info-section__value">
                {data?.company.contactPhone || '-'}
              </span>
            </div>
          </section>
          <Separator marginBottom={40} />
          <section className="info-section">
            <h2 className="info-section__title heading-5">
              {t('account_page.usc_contact.title')}
            </h2>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.usc_contact.name')}
              </span>
              <span className="info-section__value">
                {data?.company.accountManagerName || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.usc_contact.email')}
              </span>
              <span className="info-section__value">
                {data?.company.accountManagerEmail || '-'}
              </span>
            </div>
            <div className="info-section__row">
              <span className="info-section__key">
                {t('account_page.usc_contact.phone')}
              </span>
              <span className="info-section__value">
                {data?.company.accountManagerPhone || '-'}
              </span>
            </div>
          </section>
          <Popup
            heading={t('account_page.popup.title')}
            isOpen={isOpen}
            size={PopupSize.BIG}
            onClose={() => setIsOpen(false)}
          >
            <p>{t('account_page.popup.text')}</p>
            <div className="copy-box">
              <a ref={copyContainer} href={urlWithUtm}>
                {data?.company.employeeSignUpUrl}
              </a>
            </div>
            <div className="popup__buttons">
              <Button
                size={ButtonSize.SMALL}
                type={ButtonType.SECONDARY}
                text={t('account_page.popup.cancel')}
                onClick={() => setIsOpen(false)}
              />
              <CustomButton
                text={t('account_page.popup.copy')}
                onClick={onCopyClickHandler}
              />
            </div>
          </Popup>
        </div>
      </SidebarLayout>
    </>
  );
};

export default Account;
